<script>
export const registerPanelInjectKey = Symbol("registerPanel");
export const actionsPortalInjectKey = "BASE_TABS_ACTIONS_PORTAL";
</script>

<script setup>
import { computed, nextTick, onMounted, provide, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { PortalTarget } from "portal-vue";
import { TabsList, TabsRoot, TabsTrigger } from "radix-vue";
import { nanoid } from "nanoid";
import { BaseTabTriggerPill, BaseTabTriggerUnderline } from ".";
import Pill from "~/components/pill/Pill";

const panels = ref(new Map());
const activePanel = ref("");
const actionsPortal = nanoid(4);
const route = useRoute();
const emits = defineEmits(["update:activeTab"]);
watch(activePanel, (val) => {
  emits("update:activeTab", val);
});

const props = defineProps({
  look: {
    type: String,
    default: "pills",
    validator: (value) => ["pills", "underline"].includes(value),
  },
});

provide(registerPanelInjectKey, ({ id, ...panel }) => {
  panels.value.set(id, panel);

  nextTick(() => {
    if (!activePanel.value) {
      activePanel.value = id;
    }
  });
});
provide(actionsPortalInjectKey, actionsPortal);

onMounted(async () => {
  const id = route.hash?.substring(1);
  // check if a hash is provided and a tab exists
  if (!id || !panels.value.get(id)) return;

  await nextTick(() => {
    activePanel.value = id;
  });
});

const TabTriggerComponent = computed(() => (props.look === "pills" ? BaseTabTriggerPill : BaseTabTriggerUnderline));
</script>

<template>
  <TabsRoot v-model="activePanel">
    <TabsList class="flex w-full flex-wrap justify-between">
      <BaseTabTriggerList :look="look">
        <TabsTrigger v-for="([id, { name, label }], index) in panels" :key="id" :value="id" asChild>
          <TabTriggerComponent class="relative" :class="[index === panels.size - 1 && '!mr-auto']">
            {{ name }}
            <Pill v-if="label" class="absolute right-1 top-0 -translate-y-1/2" color="red" :text="label" size="xs" />
          </TabTriggerComponent>
        </TabsTrigger>
        <div class="!ml-0 flex">
          <PortalTarget :name="actionsPortal" />
        </div>
      </BaseTabTriggerList>
      <div class="flex-1">
        <slot name="afterHeader" />
      </div>
    </TabsList>
    <slot />
  </TabsRoot>
</template>
