<template>
  <input
    ref="input"
    class="flex w-full items-center rounded-md border py-px text-sm text-gray-900 shadow-sm disabled:text-gray-400"
    :class="[
      size === 'sm' && 'h-6 px-2 text-sm',
      size === 'md' && 'h-8 px-2 text-sm',
      size === 'lg' && 'h-10 px-3 text-sm',
      isValid && 'border-gray-300 bg-white',
      !isValid && 'border-red-500 bg-red-50 shadow-red-200 focus:border-red-500 focus:bg-white focus:ring-red-500',
    ]"
    :value="localValue"
    type="time"
    :name="name"
    :data-testid="`time-input-${name}`"
    v-bind="$attrs"
    @input="handleEvent('update:modelValue', $event)"
    @change="handleEvent('change', $event)"
  />
</template>

<script>
import debounce from "lodash/debounce";
import { timestringToMinutes } from "~/utils/time";

export default {
  name: "TimeInput",

  props: {
    name: {
      type: String,
      required: true,
    },

    max: {
      type: String,
      validator: (value) => !value || value.match(/^([0-1][0-9]|2[0-3]):[0-5][0-9]$/),
    },

    min: {
      type: String,
      validator: (value) => !value || value.match(/^([0-1][0-9]|2[0-3]):[0-5][0-9]$/),
    },

    modelValue: {
      type: String,
      validator: (value) => !value || value.match(/^([0-1][0-9]|2[0-3]):[0-5][0-9]$/),
    },

    allowMin: Boolean,
    allowMax: Boolean,
    size: {
      type: String,
      default: "lg",
      validator(value) {
        return ["sm", "md", "lg"].includes(value);
      },
    },
  },

  emits: ["update:modelValue", "change", "validity"],

  data() {
    return {
      localValue: this.modelValue,
      isValid: true,
    };
  },

  watch: {
    modelValue() {
      this.localValue = this.modelValue;
      this.isValid = this.validate(this.localValue);
    },

    min() {
      this.isValid = this.validate(this.localValue);
    },

    max() {
      this.isValid = this.validate(this.localValue);
    },

    isValid() {
      this.$emit("validity", this.isValid);
    },
  },

  created() {
    this.reportValidityDebounced = debounce(this.reportValidity, 350);
  },

  mounted() {
    this.isValid = this.validate(this.localValue);
  },

  methods: {
    interceptEvent(event) {
      if (!this.isValid) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
      }
    },

    handleEvent(type, event) {
      const { value } = event.target;

      this.localValue = value;
      this.isValid = this.validate(this.localValue);

      // Remove validation errors when typing
      this.removeValidationError();

      if (!this.isValid) {
        this.reportValidityDebounced();
      } else {
        // Emit the event after the validity event has been emitted
        this.$nextTick(() => {
          this.$emit(type, type === "update:modelValue" ? value : event);
        });
      }
    },

    validate(timeString) {
      if (!timeString) {
        return true;
      }

      const value = timestringToMinutes(timeString);
      const maxValue = timestringToMinutes(this.max || "23:59");
      const minValue = timestringToMinutes(this.min || "00:00");

      if (value > minValue && value < maxValue) {
        return true;
      }

      if ((this.allowMin || this.min == null) && value === minValue) {
        return true;
      }

      if ((this.allowMax || this.max == null) && value === maxValue) {
        return true;
      }

      return false;
    },

    reportValidity() {
      if (this.isValid) {
        this.removeValidationError();
      } else {
        this.reportInvalid();
      }
    },

    removeValidationError() {
      this.$refs.input?.setCustomValidity("");
      this.$refs.input?.reportValidity();
    },

    reportInvalid() {
      if (!this.min) {
        this.$refs.input?.setCustomValidity(`Tidspunkt skal være før ${this.max}`);
      } else if (!this.max) {
        this.$refs.input?.setCustomValidity(`Tidspunkt skal være efter ${this.min}`);
      } else {
        this.$refs.input?.setCustomValidity(`Tidspunkt skal være mellem ${this.min} og ${this.max}`);
      }

      this.$refs.input.reportValidity();
    },
  },
};
</script>
