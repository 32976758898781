<template>
  <Dialog title="Vælg type af overførsel" confirm="Fortsæt" data-testid="confirm-dialog" @close="onClose">
    <template #content>
      <div class="mt-6 space-y-3">
        <div class="relative flex items-start">
          <div class="flex h-5 items-center">
            <input
              id="standard"
              v-model="clearingChannel"
              value="N"
              aria-describedby="standard-description"
              name="clearingChannel"
              type="radio"
              checked
              class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
          </div>
          <div class="ml-3 text-sm">
            <label for="standard" class="text-gray-700">Standard overførsel</label>
          </div>
        </div>

        <div class="relative flex items-start">
          <div class="flex h-5 items-center">
            <input
              id="sameday"
              v-model="clearingChannel"
              value="D"
              aria-describedby="sameday-description"
              name="clearingChannel"
              type="radio"
              class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
          </div>
          <div class="ml-3 text-sm">
            <label for="sameday" class="text-gray-700">Strakssoverførsel</label>
          </div>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "~/components/dialogs/Dialog";

export default {
  components: { Dialog },

  emits: ["close"],

  data() {
    return {
      clearingChannel: "N",
    };
  },

  methods: {
    onClose(accepted) {
      if (accepted) {
        this.$emit("close", this.clearingChannel);
      } else {
        this.$emit("close", false);
      }
    },
  },
};
</script>
