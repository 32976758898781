import gql from "graphql-tag";
import { jwtDecode } from "jwt-decode";
import { apolloClient } from "~/vue-apollo";
import { SESSION_TOKEN } from "~/constants/settings";
import { BEFORE_SILENT_SIGN_IN_ATTEMPT, NO_SESSION, SESSION_EXPIRED, SIGNED_IN } from "~/constants/sessionStatuses";

export const TOKEN_EXPIRY_SAFETY_MARGIN = 30000; // Renew the token before expiry for safety's sake

const getDefaultState = () => ({
  status: BEFORE_SILENT_SIGN_IN_ATTEMPT,
  token: null,
  tokenData: null,
  refreshTimer: null,
});

export default {
  namespaced: true,
  state: getDefaultState(),

  getters: {
    status(state) {
      return state.status;
    },

    token(state) {
      return state.token;
    },

    tokenData(state) {
      return state.tokenData;
    },
  },

  mutations: {
    updateSession(state, newState) {
      Object.assign(state, newState);
    },
  },

  actions: {
    handleTokenReceived(context, token) {
      const newState = {
        status: SIGNED_IN,
        token,
        tokenData: jwtDecode(token),
      };

      localStorage.setItem(SESSION_TOKEN, token);
      context.dispatch("scheduleRefresh", newState.tokenData.exp);

      context.commit("updateSession", newState);
    },

    scheduleRefresh(context, expiry) {
      const timeToExpiry = new Date(expiry * 1000) - new Date();
      const delayWithSafetyMargin = timeToExpiry - TOKEN_EXPIRY_SAFETY_MARGIN;

      // Largest 32 bit signed integer:
      // https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/setTimeout#Maximum_delay_value
      const maxDelay = 2147483647;
      const delay = Math.min(delayWithSafetyMargin, maxDelay);

      const refreshTimer = setTimeout(() => context.dispatch("refreshSession"), delay);

      context.commit("updateSession", { refreshTimer });
    },

    clearSession(context) {
      localStorage.removeItem(SESSION_TOKEN);

      clearTimeout(context.state.refreshTimer);

      context.commit("updateSession", {
        ...getDefaultState(),
        status: NO_SESSION,
      });
    },

    async signIn(context, { email, password }) {
      try {
        const result = await apolloClient.mutate({
          mutation: gql`
            mutation signIn($credentials: CredentialsAttributes!) {
              signIn(credentials: $credentials) {
                token
              }
            }
          `,
          variables: { credentials: { email, password } },
        });

        context.dispatch("handleTokenReceived", result.data.signIn.token);
      } catch (error) {
        if (error.graphQLErrors?.[0].extensions?.code === "invalid_credentials") {
          throw new Error("Invalid email or password");
        }
      }
    },

    async refreshSession(context) {
      try {
        const result = await apolloClient.mutate({
          mutation: gql`
            mutation silentSignIn {
              silentSignIn {
                token
              }
            }
          `,
        });

        const { token } = result.data.silentSignIn;

        context.dispatch("handleTokenReceived", token);
      } catch (error) {
        if (error.graphQLErrors?.[0]?.extensions?.code === "invalid_refresh_token") {
          context.commit("updateSession", { status: SESSION_EXPIRED });
        } else {
          context.commit("updateSession", { status: NO_SESSION });
        }
      }
    },

    async signOut(context) {
      await apolloClient.mutate({
        mutation: gql`
          mutation signOut {
            signOut {
              success
            }
          }
        `,
      });

      context.dispatch("clearSession");
    },

    attemptSilentSignIn({ state, dispatch }) {
      if (state.status === BEFORE_SILENT_SIGN_IN_ATTEMPT) {
        return dispatch("refreshSession");
      }
      return Promise.resolve();
    },
  },
};
