import { notify } from "@kyvg/vue3-notification";

export default (version) => {
  notify({
    type: "info",
    title: "Ny version tilgængelig",
    text: "Opdater venligst din browser for at benytte den seneste version af systemet.",
    duration: -1,
    ignoreDuplicates: true,
    data: {
      version,
      actions: [{ primary: true, text: "Opdater nu", click: () => window.location.reload() }],
    },
  });
};
