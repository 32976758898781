import { createRouter, createWebHistory } from "vue-router";
import { hasStudentPageAccessQuery } from "~/graphql/queries/hasStudentPageAccess";
import { toast } from "~/utils/toast";
import PassThrough from "~/views/PassThrough";
import { apolloClient } from "~/vue-apollo";

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      name: "Login",
      path: "/",
      component: () => import("~/views/Login"),
    },
    {
      name: "PasswordReset",
      path: "/password-reset/:passwordResetToken",
      component: () => import("~/views/PasswordReset"),
    },
    {
      path: "/:fguInstitutionNumber",
      component: () => import("~/components/auth/LogInBarrier"),
      meta: { breadcrumb: true },
      beforeEnter: (to, from, next) => {
        if (!to.name || to.params.fguInstitutionNumber.length !== 6 || isNaN(to.params.fguInstitutionNumber)) {
          next({ name: "NotFound" });
        } else {
          next();
        }
      },
      children: [
        {
          path: "",
          component: () => import("~/layouts/Layout1"),
          children: [
            {
              path: "change-password",
              name: "ChangePassword",
              component: () => import("~/components/profile/ChangePassword"),
            },
            {
              path: "home",
              name: "Homepage",
              component: () => import("~/views/Homepage"),
            },
            {
              path: "reports",
              component: PassThrough,
              children: [
                {
                  path: "",
                  name: "ReportsIndex",
                  component: () => import("~/views/ReportsIndex"),
                },
                {
                  path: "list-extract",
                  name: "ListExtractReport",
                  component: () => import("~/views/ListExtractReport"),
                },
                {
                  path: "reduced-stay",
                  name: "ReducedStayReport",
                  component: () => import("~/views/ReducedStayReport"),
                },
                {
                  path: "stay-consents",
                  name: "StayConsentReport",
                  component: () => import("~/views/StayConsentReport"),
                },
                {
                  path: "tax-job",
                  name: "TaxJobReport",
                  component: () => import("~/views/TaxJobReport"),
                },
                {
                  path: "time-report",
                  name: "TimeReport",
                  component: () => import("~/views/TimeReport"),
                },
                {
                  path: "cancelled-events-report",
                  name: "CancelledEventsReport",
                  component: () => import("~/views/CancelledEventsReport"),
                },
                {
                  path: "cosa-reports",
                  component: PassThrough,
                  children: [
                    {
                      path: "",
                      name: "CosaReports",
                      component: () => import("~/views/CosaReportsIndex"),
                    },

                    {
                      path: ":reportId",
                      name: "CosaReportPage",
                      component: () => import("~/views/CosaReportPage"),
                    },
                  ],
                },
                {
                  path: "count-periods",
                  name: "CountPeriodReport",
                  component: () => import("~/views/CountPeriodReport"),
                },
                {
                  path: "pool-extensions",
                  name: "PoolExtensionReport",
                  component: () => import("~/views/PoolExtensionReport"),
                },
              ],
            },

            {
              path: "education-modules",
              redirect: (to) => ({
                name: "EducationModules",
                params: { ...to.params, type: "workshops" },
              }),
            },

            {
              path: "education-modules",
              component: PassThrough,
              children: [
                {
                  path: ":type",
                  component: PassThrough,
                  children: [
                    {
                      path: "",
                      name: "EducationModules",
                      component: () => import("~/views/EducationModulesIndex"),
                    },
                    {
                      path: ":educationModuleId",
                      component: PassThrough,
                      children: [
                        {
                          path: "attendances",
                          name: "EducationModule",
                          component: () => import("~/views/EducationModule"),
                          children: [
                            {
                              path: "overview",
                              name: "EducationModuleAttendanceOverview",
                              meta: { breadcrumb: false },
                              component: () => import("~/views/EducationModuleAttendanceOverview"),
                            },
                            {
                              path: "absence",
                              name: "EducationModuleAttendanceAbsence",
                              meta: { breadcrumb: false },
                              component: () => import("~/views/EducationModuleAttendanceAbsence"),
                            },
                            {
                              path: "competences",
                              name: "EducationModuleAttendanceCompetenceTable",
                              meta: { breadcrumb: false },
                              component: () => import("~/views/EducationModuleCompetenceTable"),
                            },
                            {
                              path: "teachers",
                              name: "EducationModuleTeachers",
                              meta: { breadcrumb: false },
                              component: () => import("~/views/EducationModuleUsers"),
                              props: { userRole: "teacher" },
                            },
                            {
                              path: "examiners",
                              name: "EducationModuleExaminers",
                              meta: { breadcrumb: false },
                              component: () => import("~/views/EducationModuleUsers"),
                              props: { userRole: "examiner" },
                            },
                            {
                              path: "schedule",
                              name: "EducationModuleSchedule",
                              meta: { breadcrumb: false },
                              component: () => import("~/views/EducationModuleSchedule"),
                            },
                            {
                              path: "registration-errors",
                              name: "EducationModuleRegistrationErrors",
                              meta: { breadcrumb: false },
                              component: () => import("~/views/EducationModuleRegistrationErrors"),
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },

            {
              path: "notifications",
              name: "Notifications",
              component: () => import("~/views/Notifications"),
            },

            {
              path: "notifications/feed",
              name: "NotificationFeed",
              component: () => import("~/views/NotificationFeed"),
            },

            {
              path: "search",
              name: "SearchResults",
              component: () => import("~/views/SearchResults"),
            },

            {
              path: "settings",
              component: PassThrough,
              children: [
                {
                  path: "",
                  name: "SettingsIndex",
                  component: () => import("~/views/SettingsIndex"),
                },
                {
                  path: "pay-deductions",
                  component: PassThrough,
                  children: [
                    {
                      path: "",
                      name: "PayDeductionsIndex",
                      component: () => import("~/views/PayDeductionsIndex"),
                    },
                    {
                      path: ":id",
                      name: "PayDeduction",
                      component: () => import("~/views/PayDeduction"),
                    },
                  ],
                },
                {
                  path: "screenings",
                  component: PassThrough,
                  children: [
                    {
                      path: "",
                      name: "Screenings",
                      component: () => import("~/views/Screenings"),
                    },
                  ],
                },
                {
                  path: "bi-tokens",
                  component: PassThrough,
                  children: [
                    {
                      path: "",
                      name: "BiTokens",
                      component: () => import("~/views/BiTokens"),
                    },
                  ],
                },
                {
                  path: "ip-whitelist",
                  component: PassThrough,
                  children: [
                    {
                      path: "",
                      name: "IpWhitelist",
                      component: () => import("~/views/IpWhitelist"),
                    },
                  ],
                },
                {
                  path: "exam-periods",
                  component: PassThrough,
                  children: [
                    {
                      path: "",
                      name: "ExamPeriodsIndex",
                      component: () => import("~/views/ExamPeriodsIndex"),
                    },
                    {
                      path: ":examPeriodId",
                      component: () => import("~/views/ExamPeriod"),
                      children: [
                        {
                          path: "",
                          name: "ExamPeriodExamTeams",
                          component: () => import("~/views/ExamPeriodExamTeams"),
                        },
                        {
                          path: "registrations",
                          name: "ExamPeriodExamRegistrations",
                          meta: { breadcrumb: false },
                          component: () => import("~/views/ExamPeriodExamRegistrations"),
                        },
                        {
                          name: "ExamTeam",
                          path: "exam-teams/:examTeamId",
                          component: () => import("~/views/ExamTeam"),
                          children: [
                            {
                              name: "ExamRegistrations",
                              path: "registrations",
                              meta: { breadcrumb: false },
                              component: () => import("~/views/ExamRegistrations"),
                            },
                            {
                              name: "ExamOrderLists",
                              path: "order-lists",
                              meta: { breadcrumb: false },
                              component: () => import("~/views/ExamOrderLists"),
                            },
                            {
                              name: "ExamGradingLists",
                              path: "grading-lists",
                              meta: { breadcrumb: false },
                              component: () => import("~/views/ExamGradingLists"),
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  path: "locations",
                  name: "LocationsIndex",
                  component: () => import("~/views/LocationsIndex"),
                },
                {
                  path: "ringtime-schedules",
                  component: PassThrough,
                  children: [
                    {
                      path: "",
                      name: "RingtimeSchedulesIndex",
                      component: () => import("~/views/RingtimeSchedulesIndex"),
                    },
                    {
                      path: ":ringtimeScheduleId",
                      name: "RingtimeSlotsIndex",
                      component: () => import("~/views/RingtimeSlotsIndex"),
                    },
                  ],
                },
                {
                  path: "users",
                  component: PassThrough,
                  children: [
                    {
                      path: "",
                      name: "UsersIndex",
                      component: () => import("~/views/UsersIndex"),
                    },
                    {
                      path: "user-groups",
                      name: "UserGroupsIndex",
                      component: () => import("~/views/UserGroupsIndex"),
                    },
                  ],
                },
                {
                  path: "consents",
                  component: PassThrough,
                  children: [
                    {
                      path: "",
                      name: "Consents",
                      component: () => import("~/views/Consents"),
                    },
                  ],
                },
                {
                  path: "wage-periods",
                  component: PassThrough,
                  children: [
                    {
                      path: "",
                      name: "WagePeriodsIndex",
                      component: () => import("~/views/WagePeriodsIndex"),
                    },
                    {
                      path: ":wagePeriodId",
                      component: PassThrough,
                      children: [
                        {
                          name: "WagePeriodStayWagePeriods",
                          path: "stay-wage-periods",
                          meta: { breadcrumb: true },
                          component: () => import("~/views/WagePeriodStayWagePeriods"),
                        },
                        {
                          path: "pay-runs",
                          component: PassThrough,
                          children: [
                            {
                              path: "",
                              name: "PayRunsIndex",
                              meta: { breadcrumb: true },
                              component: () => import("~/views/PayRunsIndex"),
                            },
                            {
                              path: ":payRunId",
                              name: PassThrough,
                              meta: { breadcrumb: false },
                              component: () => import("~/views/PayRunPage"),
                              children: [
                                {
                                  name: "RevenueReportPage",
                                  path: "revenue-report",
                                  meta: { breadcrumb: true },
                                  component: () => import("~/views/RevenueReportPage"),
                                },
                                {
                                  name: "PayChecksPage",
                                  path: "pay-checks",
                                  meta: { breadcrumb: true },
                                  component: () => import("~/views/PayChecksPage"),
                                },
                                {
                                  name: "PayStatementDrafts",
                                  path: "pay-statement-drafts",
                                  meta: { breadcrumb: false },
                                  component: () => import("~/views/PayStatementDrafts"),
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },

            {
              path: "students",
              component: PassThrough,
              children: [
                {
                  path: "",
                  name: "StaysIndex",
                  component: () => import("~/views/StayIndex"),
                },

                {
                  path: "ended",
                  name: "EndedStaysIndex",
                  component: () => import("~/views/EndedStaysIndex"),
                },

                {
                  path: "upcoming",
                  name: "UpcomingStaysIndex",
                  component: () => import("~/views/UpcomingStaysIndex"),
                },

                {
                  path: ":studentId",
                  component: PassThrough,
                  async beforeEnter(to, from) {
                    const response = await apolloClient.query({
                      query: hasStudentPageAccessQuery,
                      variables: {
                        id: to.params.studentId,
                      },
                      fetchPolicy: "no-cache",
                    });

                    if (response.data && !response.data.student.hasStudentPageAccess) {
                      toast.error("Ingen adgang", "Du har ikke adgang til denne elev");

                      if (from.name === undefined) {
                        return {
                          name: "StaysIndex",
                        };
                      } else {
                        return false;
                      }
                    }
                  },
                  children: [
                    {
                      path: "",
                      name: "ClassicStudentPage",
                      component: () => import("~/views/ClassicStudentPage"),
                      alias: "classic",
                    },
                    {
                      path: "schedule",
                      name: "StudentSchedule",
                      meta: { breadcrumb: false },
                      component: () => import("~/views/StudentSchedule"),
                    },
                    {
                      path: "overview",
                      name: "StudentOverview",
                      meta: { breadcrumb: false },
                      component: () => import("~/views/StudentOverview"),
                    },
                  ],
                },
              ],
            },

            {
              path: "teams",
              component: PassThrough,
              children: [
                {
                  path: "",
                  name: "Teams",
                  component: () => import("~/views/TeamsIndex"),
                },
                {
                  path: ":teamId",
                  component: () => import("~/views/TeamOverview"),
                  children: [
                    {
                      path: "",
                      name: "TeamEditPage",
                      component: () => import("~/views/TeamStaysList"),
                    },
                    {
                      path: "absence",
                      name: "TeamAbsence",
                      meta: { breadcrumb: false },
                      component: () => import("~/views/TeamAbsence"),
                    },
                    {
                      path: "schedule",
                      name: "TeamSchedule",
                      meta: { breadcrumb: false },
                      component: () => import("~/views/TeamSchedule"),
                    },
                    {
                      path: "competence-tables",
                      name: "TeamCompetenceTables",
                      meta: { breadcrumb: false },
                      component: () => import("~/views/TeamCompetenceTables"),
                    },
                    {
                      path: "registration-errors",
                      name: "TeamRegistrationErrors",
                      meta: { breadcrumb: false },
                      component: () => import("~/views/TeamRegistrationErrors"),
                    },
                  ],
                },
              ],
            },

            {
              path: "time-tables",
              name: "TimeTablesIndex",
              component: () => import("~/views/TimeTableIndex"),
            },

            {
              path: "tools",
              component: PassThrough,
              children: [
                {
                  path: "",
                  name: "ToolsIndex",
                  component: () => import("~/views/ToolsIndex"),
                },
                {
                  path: "fgu-schools",
                  name: "FguSchools",
                  component: () => import("~/views/FguSchools"),
                },
                {
                  path: "screenings-overview",
                  name: "ScreeningsReportPage",
                  component: () => import("~/views/ScreeningsReportPage"),
                },
                {
                  path: "eboks",
                  name: "EboksIndex",
                  component: () => import("~/views/EboksIndex"),
                },
                {
                  path: "companies",
                  component: PassThrough,
                  children: [
                    {
                      path: "",
                      name: "CompaniesIndex",
                      component: () => import("~/views/CompaniesIndex"),
                    },
                    {
                      path: ":companyId",
                      name: "CompanyPage",
                      component: () => import("~/views/CompanyPage"),
                    },
                  ],
                },
                {
                  path: "imported-details-lines",
                  component: PassThrough,
                  children: [
                    {
                      path: "",
                      name: "ImportedDetailsLinesIndex",
                      component: () => import("~/views/ImportedDetailsLinesIndex"),
                    },
                  ],
                },
                {
                  path: "exam-registration-events",
                  name: "ExamRegistrationEvents",
                  component: () => import("~/views/ExamRegistrationEventsLog"),
                },
                {
                  path: "competence-tables",
                  component: PassThrough,
                  children: [
                    {
                      path: "",
                      name: "CompetenceTablesIndex",
                      component: () => import("~/views/CompetenceTablesIndex"),
                    },
                    {
                      path: ":id",
                      name: "CompetenceTable",
                      component: () => import("~/views/CompetenceTable"),
                    },
                  ],
                },
                {
                  path: "kui-contacts",
                  component: PassThrough,
                  children: [
                    {
                      path: "",
                      name: "KuiContactsIndex",
                      component: () => import("~/views/KuiContactsIndex"),
                    },
                    {
                      path: ":kuiContactId",
                      name: "KuiContactPage",
                      component: () => import("~/views/KuiContactPage"),
                    },
                  ],
                },
                {
                  path: "schedule-plans",
                  component: PassThrough,
                  children: [
                    {
                      path: "",
                      name: "SchedulePlansIndex",
                      component: () => import("~/views/SchedulePlansIndex"),
                    },
                    {
                      path: ":schedulePlanId",
                      name: "SchedulePlanShow",
                      component: () => import("~/views/SchedulePlanShow"),
                    },
                  ],
                },
                {
                  path: "school-day-calendars",
                  component: PassThrough,
                  children: [
                    {
                      path: "",
                      name: "SchoolDayCalendarIndex",
                      component: () => import("~/views/SchoolDayCalendarIndex"),
                    },
                    {
                      path: ":schoolDayCalendarId",
                      name: "SchoolDayCalendar",
                      component: () => import("~/views/SchoolDayCalendar"),
                    },
                  ],
                },
                {
                  path: "schools",
                  component: PassThrough,
                  children: [
                    {
                      path: "",
                      name: "SchoolsIndex",
                      component: () => import("~/views/SchoolsIndex"),
                    },
                    {
                      path: ":schoolId",
                      name: "SchoolPage",
                      component: () => import("~/views/SchoolPage"),
                    },
                  ],
                },
                {
                  path: "stay-taxonomy-activities",
                  name: "StayTaxonomyActivities",
                  component: () => import("~/views/StayTaxonomyActivities"),
                },
                {
                  path: "taxonomy-statuses",
                  component: PassThrough,
                  children: [
                    {
                      path: "",
                      name: "TaxonomyStatusesIndex",
                      component: () => import("~/views/TaxonomyStatusesIndex"),
                    },
                    {
                      path: ":taxonomyStatusId",
                      name: "TaxonomyStatusPage",
                      component: () => import("~/views/TaxonomyStatusPage"),
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "/:fguInstitutionNumber/:pathMatch(.*)*",
          redirect: { name: "NotFound" },
        },
      ],
    },
    {
      path: "/not-found",
      name: "NotFound",
      component: () => import("~/views/NotFound"),
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        top: 152,
      };
    }

    if (savedPosition) {
      return { left: savedPosition.left, top: savedPosition.top + 24 };
    }

    return { left: 0, top: 0 };
  },
});
