const tokens = ["relative", "after:content-['']", "after:absolute", "after:inset-px", "after:ring-2", "after:ring-blue-400", "after:rounded-sm"];

export function toggleOutline() {
  const id = this.id;
  const anchor = location.hash?.replace("#", "");

  if (id === anchor) {
    this.classList.add(...tokens);
  } else {
    this.classList.remove(...tokens);
  }
}

export const highlightAnchorDirective = {
  created(element) {
    addEventListener("hashchange", toggleOutline.bind(element));
  },

  mounted(element) {
    toggleOutline.call(element);
  },

  unbind() {
    removeEventListener("hashchange", toggleOutline);
  },
};
