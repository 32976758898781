import mitt from "mitt";

export const dialogEvents = mitt();
export const dialogs = {};
export const dialogsPlugin = {
  install(app, options) {
    app.config.globalProperties.$dialogs = {};

    /**
     * Make each component in options.dialogs a function that can be called on
     * from all components in the app.
     */
    Object.keys(options.dialogs).forEach((name) => {
      const fn = (props, beforeClose) =>
        /**
         * Return a promise, so we can wait for user action.
         */
        new Promise((resolve, reject) => {
          dialogEvents.emit("show-dialog", {
            component: options.dialogs[name],
            props,
            resolve,
            reject,
            beforeClose,
          });
        });
      app.config.globalProperties.$dialogs[name] = fn;
      dialogs[name] = fn;
    });
  },
};
