<template>
  <Dialog :title="title" :confirm="confirm" data-testid="confirm-dialog" @close="onClose">
    <template #content>
      <BaseTextarea v-model="comment" name="comment" label="Kommentar" class="w-full" />
    </template>
  </Dialog>
</template>

<script>
import Dialog from "~/components/dialogs/Dialog";

export default {
  components: { Dialog },

  props: {
    title: {
      type: String,
      required: true,
    },

    confirm: {
      type: String,
      required: true,
    },

    approvalComment: {
      type: String,
      required: true,
    },
  },

  emits: ["close"],

  data() {
    return {
      comment: this.approvalComment || "",
    };
  },

  methods: {
    onClose(accepted) {
      this.$emit("close", { accepted: accepted, comment: this.comment });
    },
  },
};
</script>
