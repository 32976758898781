<template>
  <button
    v-if="editable"
    class="inline-flex items-center rounded-full border text-xs font-medium focus:outline-none"
    :class="[
      `bg-${color}-100 text-${color}-800 border-${color}-200`,
      size === 'xs' && 'h-5 px-1 py-0 text-xs font-medium leading-5',
      size === 'sm' && 'h-6 px-1 py-0 text-sm leading-6',
      size === 'md' && 'h-8 px-2 py-0 text-sm leading-8',
      size === 'lg' && 'h-10 px-2 py-0 text-sm leading-10',
    ]"
  >
    {{ text }}
    <svgicon v-if="editable" name="solid-chevron-down" class="-mr-1 h-4 w-4" />
  </button>
  <span
    v-else
    class="inline-flex items-center rounded-full border font-medium focus:outline-none"
    :class="[
      `bg-${color}-100 text-${color}-800 border-${color}-200`,
      size === 'xs' && 'h-4 px-1.5 py-0 text-xs font-medium leading-4',
      size === 'sm' && 'h-5 px-2.5 py-0 text-xs leading-5',
      size === 'md' && 'h-6 px-2.5 py-0 text-sm leading-6',
    ]"
  >
    {{ text }}
  </span>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "gray",
    },

    text: {
      type: [String, Number],
      required: true,
    },

    size: {
      type: String,
      default: "sm",
      validator: (v) => ["xs", "sm", "md", "lg"].includes(v),
    },

    editable: Boolean,
  },
};
</script>
